import { APPLIED_FILTERS } from './constants'
import { initialState } from './FilterProvider'
import { IFilterAction, IFilterState } from './types'

export default function filterReducer(
  state: IFilterState = initialState,
  action: IFilterAction,
) {
  const payload = action.payload

  switch (action.type) {
    case APPLIED_FILTERS:
      return {
        ...state,
        appliedSearch: payload.appliedSearch,
        appliedCategories: payload.appliedCategories,
        appliedManufacturers: payload.appliedManufacturers,
        appliedColors: payload.appliedColors,
        appliedEffects: payload.appliedEffects,
        appliedFiringPatterns: payload.appliedFiringPatterns,
        appliedDebrisProfiles: payload.appliedDebrisProfiles,
        appliedCalibers: payload.appliedCalibers,
        appliedPerformanceHeights: payload.appliedPerformanceHeights,
        appliedPriceFilters: payload.appliedPriceFilters,
        appliedProductLists: payload.appliedProductLists,
        appliedSortOptions: payload.appliedSortOptions,
      }

    default:
      return state
  }
}
