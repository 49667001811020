import {
  PRODUCT_QUERY_CATEGORIES,
  PRODUCT_QUERY_LISTS,
  PRODUCT_QUERY_MANUFACTURERS,
  PRODUCT_QUERY_COLORS,
  PRODUCT_QUERY_EFFECTS,
  PRODUCT_QUERY_FIRING_PATTERNS,
  PRODUCT_QUERY_DEBRIS_PROFILES,
  PRODUCT_QUERY_CALIBERS,
  PRODUCT_QUERY_PERFORMANCE_HEIGHTS,
  PRODUCT_QUERY_PRICE,
  PRODUCT_QUERY_SORT,
  PRODUCT_QUERY_PAGE,
  PRODUCT_QUERY_SEARCH,
  ALL_FILTER_QUERY_GROUPS_IN_ORDER,
} from '../constants/constants'
import { sortByDefaultSetting } from '../constants/db'
import { PUBLIC_API_URL } from '../context/apiconfig'
import { IUserProfile } from '../queries/user/types'
import history from '../context/history'
import { SHOP_LIST_PATH } from '../constants/constants'
import { isProd } from './EnvDetect'
import { HIDE_GLOBAL_HEADER_PATHS } from '../routes'

export const CART_PRODUCT_UPDATE_DEBOUNCE_DELAY = 750
export const DEFAULT_DEBOUNCE_DELAY = 300
export const PRODUCTS_PER_PAGE = 50
export const ORDERS_PER_PAGE = 5

export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID
  ? process.env.REACT_APP_PROJECT_ID
  : '11' // default to 11 / wholesale as backup incase a project doesn't have an up-to-date .env file

export const onDocClick = (
  fileType: string,
  documentName: string,
  orders_id: string | undefined,
) => {
  window.location.href = `${PUBLIC_API_URL}documents/${fileType}/${documentName}/${orders_id}`
}

export const onPricelistDocClick = (listName: string, sortWord: string) => {
  const urlSuffix = isProline()
    ? `${listName}-${sortWord}-proline`
    : `${listName}-${sortWord}`
  if (isProd()) {
    window.location.href = `${PUBLIC_API_URL}documents/s3/pricelist/${urlSuffix}`
  } else {
    window.location.href = `${PUBLIC_API_URL}documents/s3/pricelist/${urlSuffix}/isNotProd`
  }
}

export const onExcelDocClick = (
  getSpecifications: string,
  listName: string,
  levelId: number,
) => {
  window.location.href = `${PUBLIC_API_URL}documents/${getSpecifications}/${listName}/${levelId}/${PROJECT_ID}`
}

//Validators
export const validatePhoneNumber = (value: string | undefined) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/gi, '')
  if (phoneNumber === '') {
    return true
  } else if (/^(\+?1)|(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
    return true
  } else {
    return false
  }
}

//AutoMasking Mobile & Phone Numbers
export const automaskMobilePhoneNumber = (value: string) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/g, '')
  if (phoneNumber.length === 10) {
    phoneNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '+1($1) $2-$3')
  } else if (phoneNumber.length === 13) {
    phoneNumber = phoneNumber.replace(
      /^(\+?1)(\d{3})[ ](\d{3})(\d{4})$/,
      '$1($2) $3-$4',
    )
  } else {
    if (/^(\+?1)(\d{3})[ ](\d{3})(\d{4})./.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 13)
    } else if (/^(\+?1)(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 11)
    }
  }
  return phoneNumber
}

export const automaskPhoneNumber = (value: string) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/g, '')
  if (phoneNumber.length === 10) {
    phoneNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
  } else if (phoneNumber.length === 11) {
    phoneNumber = phoneNumber.replace(
      /^(\d{3})[ ](\d{3})(\d{4})$/,
      '($1) $2-$3',
    )
  } else {
    if (/^(\d{3})[ ](\d{3})(\d{4})./.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 11)
    } else if (/^(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 10)
    }
  }
  return phoneNumber
}

export const automaskEmail = (value: string) => {
  return new Promise<string>((resolve) => {
    let email = typeof value === 'undefined' ? '' : value
    email = email.replace(/\s/g, '')
    resolve(email)
  })
}

export const priceApiToCss = (object: any) => {
  let bootstrapSting = ''
  if (object.strikethrough > 0) {
    bootstrapSting += ' text-decoration-line-through'
  }
  if (object.emphasize > 0) {
    bootstrapSting += ' fw-bold text-accent'
  }
  if (object.muted > 0) {
    bootstrapSting += ' text-muted'
  }
  if (object.small) {
    bootstrapSting += ' small'
  }

  return bootstrapSting
}

export const stringToNumberArray = (string: string | null) => {
  let array = string && string.split(',').map((el: any) => +`${el}`)
  return array
}

export const determineChanges = (
  oldValues: any,
  newValues: any,
  hiddenInputs: Array<string> = [],
) => {
  let tempChangedValues: any = {}
  hiddenInputs.forEach((input_name) => {
    tempChangedValues[input_name] = oldValues[input_name]
  })

  for (const property in newValues) {
    if (
      newValues[property] !== oldValues[property] &&
      newValues[property] !== undefined
    ) {
      tempChangedValues = {
        ...tempChangedValues,
        ...{ [property]: newValues[property] },
      }
    }
  }

  return tempChangedValues
}

export const stringToBoolean = (stringValue: string | undefined) => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true

    case 'false':
    case 'no':
    case '0':
    case null:
    case undefined:
      return false

    default:
      return false
  }
}

export const stringEtcToBoolean = (
  stringValue: string | undefined | null | boolean,
) => {
  if (typeof stringValue === 'string') {
    const lowerCaseValue = stringValue.toLowerCase().trim()
    switch (lowerCaseValue) {
      case 'true':
      case 'yes':
      case '1':
        return true

      case 'false':
      case 'no':
      case '0':
      case null:
      case undefined:
        return false

      default:
        return false
    }
  } else if (typeof stringValue === 'number') {
    if (stringValue === 1) {
      return true
    } else if (stringValue === 0) {
      return false
    }
  }
  return stringValue ?? false
}

interface KeyboardEvent {
  key: string
}
export const blurInputOnKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }
}

export const getProductsFiltersKeyFromSearchParams = (
  searchParamsState: any,
) => {
  return {
    search_term: searchParamsState.get(PRODUCT_QUERY_SEARCH) || '',
    category:
      stringToNumberArray(searchParamsState.get(PRODUCT_QUERY_CATEGORIES)) ||
      [],
    manufacturer:
      stringToNumberArray(searchParamsState.get(PRODUCT_QUERY_MANUFACTURERS)) ||
      [],
    color:
      stringToNumberArray(searchParamsState.get(PRODUCT_QUERY_COLORS)) || [],
    effect:
      stringToNumberArray(searchParamsState.get(PRODUCT_QUERY_EFFECTS)) || [],
    firing_pattern:
      stringToNumberArray(
        searchParamsState.get(PRODUCT_QUERY_FIRING_PATTERNS),
      ) || [],
    debris_profile:
      stringToNumberArray(
        searchParamsState.get(PRODUCT_QUERY_DEBRIS_PROFILES),
      ) || [],
    caliber:
      stringToNumberArray(searchParamsState.get(PRODUCT_QUERY_CALIBERS)) || [],
    performance_height:
      stringToNumberArray(
        searchParamsState.get(PRODUCT_QUERY_PERFORMANCE_HEIGHTS),
      ) || [],
    price:
      stringToNumberArray(searchParamsState.get(PRODUCT_QUERY_PRICE)) || [],
    list: stringToNumberArray(searchParamsState.get(PRODUCT_QUERY_LISTS)) || [],
    sort: parseInt(
      searchParamsState.get(PRODUCT_QUERY_SORT) ||
        sortByDefaultSetting.toString(),
    ),
    page: parseInt(searchParamsState.get(PRODUCT_QUERY_PAGE) || 1),

    items_per_page: PRODUCTS_PER_PAGE,
  }
}

export const updatedProductsAfterQuantityChange = (
  response: any,
  existingProductsList: any,
) => {
  return existingProductsList.map(
    (product: { products_id: any; final_price: number }) =>
      product?.products_id === response?.data?.data?.products_id
        ? {
            ...product,
            id: response.data.data.id,
            quantity: response.data.data.quantity,
            total_price: response.data.data.quantity * product.final_price,
            success: response.data.success,
            status: response.data.status,
            message: response.data.message,
          }
        : product,
  )
}

export const updatedSingleProductAfterQuantityChange = (
  response: any,
  existingProduct: any,
) => {
  return existingProduct?.products_id === response?.data?.data?.products_id
    ? {
        ...existingProduct,
        id: response.data.data.id,
        quantity: response.data.data.quantity,
        total_price: response.data.data.quantity * existingProduct.final_price,
        success: response.data.success,
        status: response.data.status,
        message: response.data.message,
      }
    : existingProduct
}

export const getContactPhone = (user_profile: IUserProfile) => {
  if (user_profile.customers_mobile_phone) {
    return user_profile.customers_mobile_phone.slice(2)
  } else if (user_profile.customers_telephone) {
    return user_profile.customers_telephone
  } else {
    return null
  }
}

export const buildShopListUrlParams = (
  name: string,
  value: string,
  limitToOne: boolean = false,
) => {
  const searchParams = new URLSearchParams(window.location.search)

  history.push(
    `/${SHOP_LIST_PATH}${buildNewUrlQuery(
      searchParams,
      name,
      value,
      limitToOne,
    )}`,
  )
}

// keep sequence in URL consistent so as to leverage data from Tanstack Query cache
export const buildNewUrlQuery = (
  searchParams: any,
  listNameFromButton: string,
  listValueFromButton: string,
  limitToOne?: boolean,
) => {
  const processSelectedFilterGroup = () => {
    let toggledArray: Array<string> = []

    if (searchParams.has(listNameFromButton)) {
      const subqueryTargetValues = searchParams.get(listNameFromButton)

      if (subqueryTargetValues.split(',').includes(listValueFromButton)) {
        if (subqueryTargetValues.length > 0) {
          if (limitToOne) {
            toggledArray = [listValueFromButton]
          } else {
            toggledArray = subqueryTargetValues
              .split(',')
              .filter(function (v: string) {
                return v !== listValueFromButton
              })
          }
        }
      } else {
        if (limitToOne) {
          toggledArray = [listValueFromButton]
        } else {
          toggledArray = [
            ...subqueryTargetValues.split(','),
            listValueFromButton,
          ].sort((a, b) => Number(a) - Number(b))
        }
      }
    } else {
      toggledArray = [listValueFromButton]
    }

    return toggledArray
  }

  const buildReturnQuery = () => {
    let tempQueryString: string = ''

    // loop the array of consts; the array sequence is the same as it'll appear in the url
    ALL_FILTER_QUERY_GROUPS_IN_ORDER.forEach(
      (groupName: string, index: number) => {
        if (listNameFromButton !== groupName) {
          if (searchParams.get(groupName)?.length > 0) {
            tempQueryString += tempQueryString.length === 0 ? '?' : '&'
            tempQueryString += `${groupName}=${searchParams.get(groupName)}`
          } else {
            // do nothing
          }
        } else {
          const updatedIds = processSelectedFilterGroup()

          if (updatedIds?.length) {
            if (
              groupName === PRODUCT_QUERY_SORT &&
              listValueFromButton === sortByDefaultSetting.toString()
            ) {
              // do nothing, so we keep default Sort By out of url
            } else {
              tempQueryString += tempQueryString.length === 0 ? '?' : '&'
              tempQueryString += `${groupName}=${updatedIds}`
            }
          } else {
            // do nothing
          }
        }
      },
    )

    return tempQueryString
  }

  return buildReturnQuery()
}

export const truncateText = (
  text: string,
  length: number,
  replace: string = '',
) => {
  if (text.length > length) {
    return text.substring(0, length) + replace
  }
  return text
}

export const isProline = () => {
  return PROJECT_ID === '14'
}

export const parseJwt = (token: string) => {
  if (!token) {
    return null
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonStr = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  return JSON.parse(jsonStr)
}

export const isImpersonate = () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const jwtPayload: any = parseJwt(user?.access_token)

  return jwtPayload?.is_impersonate
}

export const isHideGlobalHeaderPath = (path: string) => {
  return HIDE_GLOBAL_HEADER_PATHS.includes(path)
}
