import React, { useState, ReactElement } from 'react'
import { Form, FloatingLabel, NavDropdown, Row, Col } from 'react-bootstrap'
import { Link, useHref } from 'react-router-dom'
import { IconContext } from 'react-icons'
import RwIcon from './RwIcon'
import { useUserContext } from '../../context/user/UserProvider'

export interface RwDesktopHeaderItemProps {
  containerClassProps: string
  textProps: string
  toLinkProps?: string
  onClickProps?: () => void
  hrefProps?: string
  icon?: string
  target?: string
  [x: string]: any
}

const RwDesktopHeaderItem: React.FC<RwDesktopHeaderItemProps> = ({
  containerClassProps,
  textProps,
  toLinkProps = '',
  onClickProps,
  hrefProps,
  icon,
  target = '_blank',
  ...rest
}) => {
  const { isDarkMode } = useUserContext()

  if (icon) {
    const iconNew = `${icon}${isDarkMode ? 'Dm' : ''}`
    return (
      <NavDropdown.Item href={hrefProps} target={target}>
        <Row>
          <Col className="d-flex align-items-center">
            <span className="me-2">{textProps}</span>
            <IconContext.Provider value={{ className: 'react-icons' }}>
              {<RwIcon icon={iconNew} />}
            </IconContext.Provider>
          </Col>
        </Row>
      </NavDropdown.Item>
    )
  } else {
    return (
      <NavDropdown.Item href={hrefProps} target={target}>
        <Row>
          <Col
            xs="8"
            className={`d-flex ${
              hrefProps ? '' : 'fw-bold'
            } align-items-center`}
          >
            {textProps}
          </Col>
        </Row>
      </NavDropdown.Item>
    )
  }
}

export default RwDesktopHeaderItem
