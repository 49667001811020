import {
  PRODUCT_QUERY_PAGE,
  PRODUCT_QUERY_SORT,
  PRODUCT_QUERY_CATEGORIES,
  PRODUCT_QUERY_MANUFACTURERS,
  PRODUCT_QUERY_COLORS,
  PRODUCT_QUERY_EFFECTS,
  PRODUCT_QUERY_PRICE,
  PRODUCT_QUERY_LISTS,
  PRODUCT_QUERY_FIRING_PATTERNS,
  PRODUCT_QUERY_DEBRIS_PROFILES,
  PRODUCT_QUERY_CALIBERS,
  PRODUCT_QUERY_PERFORMANCE_HEIGHTS,
} from '../../constants/constants'

export const filterKeys = {
  all: ['filters'] as const,
  sortOptions: () => [...filterKeys.all, PRODUCT_QUERY_SORT] as const,
  categories: () => [...filterKeys.all, PRODUCT_QUERY_CATEGORIES] as const,
  manufacturers: (search_term: string | null) =>
    [...filterKeys.all, PRODUCT_QUERY_MANUFACTURERS, search_term] as const,
  colors: () => [...filterKeys.all, PRODUCT_QUERY_COLORS] as const,
  effects: () => [...filterKeys.all, PRODUCT_QUERY_EFFECTS] as const,
  firingPatterns: () =>
    [...filterKeys.all, PRODUCT_QUERY_FIRING_PATTERNS] as const,
  debrisProfiles: () =>
    [...filterKeys.all, PRODUCT_QUERY_DEBRIS_PROFILES] as const,
  calibers: () => [...filterKeys.all, PRODUCT_QUERY_CALIBERS] as const,
  performanceHeights: () =>
    [...filterKeys.all, PRODUCT_QUERY_PERFORMANCE_HEIGHTS] as const,
  priceFilters: (status: boolean) =>
    [...filterKeys.all, status, PRODUCT_QUERY_PRICE] as const,
  productLists: () => [...filterKeys.all, PRODUCT_QUERY_LISTS] as const,
  page: () => [...filterKeys.all, PRODUCT_QUERY_PAGE] as const,
}
