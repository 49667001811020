import React from 'react'
import { Link } from 'react-router-dom'
import SideNavMenu from './SideNavMenu'
import { Nav, Stack } from 'react-bootstrap'
import {
  SHOP_LIST_PATH,
  showAboveBreakpoint,
} from '../../../constants/constants'
import { useProductListsData } from '../../../queries/filters'
import { IHamburgerMenuItems } from '../../../context/site/types'
import { PRODUCT_QUERY_LISTS } from '../../../constants/constants'
import history from '../../../context/history'
import { useUserContext } from '../../../context/user/UserProvider'
import { isProline } from '../../../helpers'
import { useProductContext } from '../../../context/products/ProductProvider'

interface IBottomHeaderProps {
  hamburgerMenuItems: IHamburgerMenuItems | null
}

const BottomHeader: React.FC<IBottomHeaderProps> = ({ hamburgerMenuItems }) => {
  const { data: productLists } = useProductListsData()
  const { isDarkMode } = useUserContext()
  const { setPage } = useProductContext()

  const onClick = (e: any) => {
    setPage(1)
    const listItemId: string = e.target.id
    history.push(`${SHOP_LIST_PATH}?${PRODUCT_QUERY_LISTS}=${listItemId}`)
  }

  return (
    <div className={`border-top mx-1 pe-3 ${showAboveBreakpoint}`}>
      <Stack direction="horizontal">
        <SideNavMenu hamburgerMenuItems={hamburgerMenuItems} />
        <div>
          <div className="product_lists_nav_bar ms-3">
            <Nav>
              {productLists?.map((list: any, index: number) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    className={`${isDarkMode ? 'text-light' : ''}`}
                    as={Link}
                    to={`/shoplist?${PRODUCT_QUERY_LISTS}=${list.value}`}
                    key={`product-list-${list.value}`}
                    id={list.value}
                    onClick={(e) => onClick(e)}
                    data-bs-value={list.value}
                  >
                    {list.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
              <Nav.Item>
                <Nav.Link
                  className={`${isDarkMode ? 'text-light' : ''}`}
                  as={Link}
                  to="/shoplist"
                  onClick={(e) => onClick(e)}
                >
                  All Products
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={isProline() ? '/contact' : '//76fireworks.com/contact'}
                  target={isProline() ? '_self' : '_blank'}
                  className={`${isDarkMode ? 'text-light' : ''}`}
                  onClick={(e) => onClick(e)}
                >
                  Contact Us
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </Stack>
    </div>
  )
}

export default BottomHeader
