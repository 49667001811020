import React from 'react'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { ICustomerData } from '../../../queries/user/types'
import PriceListDocuments from '../Account/PriceListDocuments'

interface IPriceListDropdown {
  customer: ICustomerData
}
const PriceListDropdown: React.FC<IPriceListDropdown> = ({ customer }) => {
  const { appSettings } = useSiteContext()
  return (
    <>
      <div className="p-2 " style={{ width: '250px' }}>
        <PriceListDocuments
          customer_pricelist_name_short={
            appSettings?.use_legacy_pricing
              ? customer?.pricelist_name_short
              : customer?.price_levels_short_name
          }
          customer_price_levels_id={
            appSettings?.use_legacy_pricing
              ? customer?.pricelist_id
              : customer?.price_levels_id
          }
        />
      </div>
    </>
  )
}

export default PriceListDropdown
