import { createContext, useReducer, useContext } from 'react'
import { APPLIED_FILTERS } from './constants'
import filterReducer from './FilterReducer'
import { IFilterState } from './types'
import { IContextProviderProps } from '../../types/interfaces'
import { sortByDefaultSetting } from '../../constants/db'

export const initialState: IFilterState = {
  appliedSearch: '',
  appliedCategories: [],
  appliedManufacturers: [],
  appliedColors: [],
  appliedEffects: [],
  appliedFiringPatterns: [],
  appliedDebrisProfiles: [],
  appliedCalibers: [],
  appliedPerformanceHeights: [],
  appliedPriceFilters: [],
  appliedProductLists: [],
  appliedSortOptions: [],
  appliedPage: 1,
  adjustAppliedFilters: () => {},
}

export const FilterContext = createContext<IFilterState>(initialState)

export const useFilterContext = () => useContext(FilterContext)

export const FilterProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(filterReducer, initialState)

  const adjustAppliedFilters = async (
    search_term: any,
    selectedCategories: any,
    selectedManufacturers: any,
    selectedColors: any,
    selectedEffects: any,
    selectedFiringPatterns: any,
    selectedDebrisProfiles: any,
    selectedCalibers: any,
    selectedPerformanceHeights: any,
    selectedPriceFilters: any,
    selectedProductLists: any,
    selectedSortOptions: any,
    selectedPage: any,
  ) => {
    const newTargetFilterSelections = {
      appliedSearch: search_term,
      appliedCategories: selectedCategories,
      appliedManufacturers: selectedManufacturers,
      appliedColors: selectedColors,
      appliedEffects: selectedEffects,
      appliedFiringPatterns: selectedFiringPatterns,
      appliedDebrisProfiles: selectedDebrisProfiles,
      appliedCalibers: selectedCalibers,
      appliedPerformanceHeights: selectedPerformanceHeights,
      appliedPriceFilters: selectedPriceFilters,
      appliedProductLists: selectedProductLists,
      appliedSortOptions: selectedSortOptions.length
        ? selectedSortOptions
        : [sortByDefaultSetting],
      appliedPage: selectedPage,
    }

    dispatch({
      type: APPLIED_FILTERS,
      payload: newTargetFilterSelections,
    })
  }

  const value = {
    appliedSearch: state.appliedSearch,
    appliedCategories: state.appliedCategories,
    appliedManufacturers: state.appliedManufacturers,
    appliedColors: state.appliedColors,
    appliedEffects: state.appliedEffects,
    appliedFiringPatterns: state.appliedFiringPatterns,
    appliedDebrisProfiles: state.appliedDebrisProfiles,
    appliedCalibers: state.appliedCalibers,
    appliedPerformanceHeights: state.appliedPerformanceHeights,
    appliedPriceFilters: state.appliedPriceFilters,
    appliedProductLists: state.appliedProductLists,
    appliedSortOptions: state.appliedSortOptions,
    appliedPage: state.appliedPage,
    adjustAppliedFilters,
  }
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}
