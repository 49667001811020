import './App.scss'
import './scss/custom.scss'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './routes'
import { BrowserRouter } from 'react-router-dom'
import Header from './components/organisms/Header'
import Footer from './components/organisms/Common/Footer'

import { UserProvider } from './context/user/UserProvider'
import { ProductProvider } from './context/products/ProductProvider'
import { FilterProvider } from './context/filters/FilterProvider'
import { SiteProvider } from './context/site/SiteProvider'
import { ShowHideProvider } from './context/showhide/ShowHideProvider'
import { ToastContainer } from 'react-toastify'
import { Flip } from 'react-toastify'
import ModalDeadFish from './components/organisms/Common/Modal/ModalDeadFish'
import { CookiesProvider } from 'react-cookie'

const App = () => {
  return (
    <BrowserRouter>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <ShowHideProvider>
          <UserProvider>
            <FilterProvider>
              <ProductProvider>
                <SiteProvider>
                  <div className="page_container">
                    <Header />
                    <Routes />
                    <ModalDeadFish />
                    <ToastContainer
                      transition={Flip}
                      theme="colored"
                      autoClose={3000}
                    />
                  </div>
                  <Footer />
                </SiteProvider>
              </ProductProvider>
            </FilterProvider>
          </UserProvider>
        </ShowHideProvider>
      </CookiesProvider>
    </BrowserRouter>
  )
}

export default App
