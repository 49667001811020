import { IProductFilterParams } from './types'

export const productKeys = {
  all: ['products'] as const,
  products: (
    filters: IProductFilterParams,
    status: boolean,
    cartId: number,
    page: number,
  ) => [...productKeys.all, { filters }, status, cartId, page] as const,
  product: (products_url: string | undefined) =>
    [...productKeys.all, products_url] as const,
  productSearch: (search_term: string | undefined, status: boolean) =>
    [...productKeys.all, search_term, status] as const,
  productBanners: (filters: IProductFilterParams) =>
    [...productKeys.all, { filters }, 'banner'] as const,
  carts: ['carts'] as const,
  cartSummary: ['cartSummary'] as const,
  cart: ['cart'] as const,
  cartTotals: ['cartTotals'] as const,
  cartTotal: ['cartTotal'] as const,
  cartOptionFees: ['cartOptionFees'] as const,
  cartShippingAddress: ['cartShippingAddress'] as const,
  cartShippingAddressModal: ['cartShippingAddressModal'] as const,
  shippingQuotes: ['shippingQuotes'] as const,
  shippingQuote: ['shippingQuote'] as const,
  couponCode: ['couponCode'] as const,
  savedCartsWithItems: ['savedCartsWithItems'] as const,
  creditCardResponse: ['creditCardResponse'] as const,
  cartBilling: ['cartBilling'] as const,
  creditCardPayment: ['creditCardPayment'] as const,
}
